<!-- eslint-disable -->
<template>
    <h3>Overview</h3>
    <section id="overview">
        <div class="apexChart">
            <apexchart ref="chart" width="100%" type="line" :options="updateChart" :series="this.series.chartSeries"></apexchart>
        </div>
    </section>
</template>

<script>
/* eslint-disable */
import VueApexCharts from 'vue3-apexcharts';
import defaultOptions from '../../../charts/options.js';

export default {
    name: "Overview",
    props: {
        overviewData: {
            type: Object,
            required: true,
        },
        titleText: {
            type: String,
            default: ''
        },
        lineColor: {
            type: String,
            default: '#00ff91'
        },
        dataType: {
            type: Object,
            default: {
                label: 'ft',
                type: 'Distance',
            }
        },
        axisMins: {
            type: Object,
        },
    },
    computed: {
        updateChart() {
            const defaults = defaultOptions.defaultOptions;
            // console.log(defaults)

            const lineColors = this.series.lineColors;
            const curveArray = this.series.curveArr
            const widthArray = this.series.widthArr
            const dashArray = this.series.dashArr
            const sizeArray = this.series.sizeArr

            const chartOptions = {...defaults, ...{
                annotations: {
                    // These should be arrays of formatted objects according to ApexCharts documentation.
                    // yaxis: this.annotations.yaxis,
                    // xaxis: this.annotations.xaxis,
                    points: this.points,
                },
                colors: lineColors,
                xaxis: {
                    // min: this.axisMins.x.min,
                    // max: this.axisMins.x.max,
                    type: 'numeric',
                    ...defaults.xaxis,
                },
                yaxis: {
                    show: false,
                    type: 'numeric',
                },
                stroke: {
                    curve: curveArray,
                    width: widthArray,
                    dashArray: dashArray,
                    ...defaults.stroke,
                },
                markers: {
                    // size: sizeArray,
                },
                tooltip: {
                    enabledOnSeries: [0],
                    y: {
                        formatter: (val) => `${val}${this.dataType.label}`,
                        title: {
                            formatter: () => `Y Distance:`,
                        }
                    },
                    // ...defaults.tooltip,
                    x: {
                        show: true,
                        formatter: (val) => `X Distance: ${val}${this.dataType.label}`,
                    },
                    marker: {
                        show: false,
                    },
                    theme: 'dark',
                    // shared: false,
                    // intersect: true,
                },
                // chart: {
                //     height: '100%',
                // },
            }}

            return chartOptions;
        },
        annotations() {
            if (!this.overviewData.data) {
                return null;
            }
            //! TODO: add annotations
            // const ideals = this.overviewData.ideals
            
            // const yaxis = [];
            // const xaxis = [];

            // ideals.forEach(ideal => {
            //     ideal.yaxis = {
            //         min: ideal.min,
            //         max: ideal.max,
            //     }
            // })
            // return this.overviewData.ideals;
        },
        points() {
            if (!this.overviewData.labels) {
                return null;
            }
            const labels = this.overviewData.labels;
            
            const points = [];

            labels.forEach(label => {
                points.push({
                    x: label.coordinates.x,
                    y: label.coordinates.y,
                    marker: {
                        size: 10,
                        fillColor: '#00ff91', // add label.color here
                        strokeColor: 'blue', // add label.color here
                        radius: 2,
                    },
                    label: {
                        text: label.text,
                        style: {
                            color: '#fff',
                            background: '#4287f5',
                            padding: '5px',
                            fontSize: '16px',
                            borderRadius: '5px',
                            // fontFamily: 'Roboto',
                            fontWeight: 'bold',
                        },
                    },
                })
            })

            return points
        },
        series() {
            // console.log('Series called')
            // Reset existing arrays
            const lineColors = [];
            const curveArr = [];
            const widthArr = [];
            const dashArr = [];
            const sizeArr = [];

            if (!this.overviewData.data) {
                return [];
            }

            // console.log(this.overviewData)

            const series = [];

            if (this.overviewData.ideals) {
                this.overviewData.ideals.forEach(ideal => {
                    series.push({ name: `Ideal ${ideal.text}`, data: ideal.coordinates });
                    lineColors.push(`#8fffa7`);
                    curveArr.push('straight');
                    widthArr.push(3);
                    dashArr.push(10);
                    sizeArr.push(0);
                });
            }

            const labeledData = this.overviewData.data.actual;

            if (labeledData) {
                labeledData.forEach(labelType => {
                    let segments = labelType.data;
                    let name = labelType.label;
                    let color = labelType.color;
                    let idx = 0;
                    segments.forEach(segment => {
                        series.push({ name: `${name}${idx}`, data: segment });
                        lineColors.push(color);
                        dashArr.push(0);
                        sizeArr.push(0);
                        curveArr.push('straight');
                        widthArr.push(5);
                        idx++;
                    });
                });
            }

            return { chartSeries: series, lineColors, curveArr, widthArr, dashArr, sizeArr };
        },
    },
}
</script>

<style scoped>
/* eslint-disable */
#overview {
    /* height: 50vh; */
    overflow: hidden;
}
h3 {
    font-size: 2.5em;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .apexChart {
        height: 80%;
    }
}
</style>