<!-- eslint-disable prettier/prettier !-->
<template>
    <section>
        <div class="apexChart">
            <apexchart ref="chart" height="125%" width="100%" :options="options" :series="series"></apexchart>
        </div>
    </section>
</template>

<script>
/* eslint-disable */
import VueApexCharts from 'vue3-apexcharts';
import { defaultOptions } from '../../charts/options.js';

export default {
    name: "RatingRadial",
    props: {
        series: {
            type: Array,
            required: true
        },
        titleText: {
            type: String,
            default: ''
        },
        changeColor: {
            type: String,
            default: '#ff0000'
        },
        yOffset: {
            type: String,
            default: '20'
        },
        chartHeight: {
            type: String,
            default: '400px'
        },
    },
    data() {
        return {
            options: {
                chart: {
                    type: 'radialBar',
                    // offsetY: `${this.yOffset}`,
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        hollow: {
                            size: '30%',
                        },
                        track: {
                            background: '#0f445a',
                            strokeWidth: '100%',
                            margin: 10, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '1.25em',
                                color: '#fff',
                                offsetY: -12,
                            },
                            value: {
                                show: true,
                                fontSize: '2em',
                                color: `${this.changeColor}`,
                                offsetY: 5,
                                formatter: function(val) {
                                    return (val/10).toFixed(2);
                                }
                            },
                            total: {
                                show: true,
                                label: 'Change',
                                color: `#fff`,
                                formatter: function(w) {
                                    if (w.globals.series[0] > w.globals.series[1]) {
                                        return `+${Math.abs(w.globals.seriesTotals[0]/10 - w.globals.seriesTotals[1]/10).toFixed(2)}`;
                                    } else {
                                        return `-${Math.abs(w.globals.seriesTotals[0]/10 - w.globals.seriesTotals[1]/10).toFixed(2)}`;
                                    }
                                }
                            },
                        },
                    }
                },
                legend: {
                    show: false,
                },
                colors: ['#38b6ff', '#ff752b'],
                stroke: {
                    lineCap: 'round',
                },
                labels: ['Current', 'Previous'],
                yaxis: {
                    show: false,
                    min: 0,
                    max: 10,
                },
                xaxis: {
                    show: false,
                },
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    },
                },
            },
        }
    },
}

</script>

<style scoped>
/* eslint-disable */

.apexChart {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
	padding: 0;
    margin: 0;
}
</style>
