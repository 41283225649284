<!-- eslint-disable prettier/prettier !-->
<template>
    <Navbar headingTxt="" />
    <section id="full">
        <section id="interactions">
            <div class="loader" v-bind:class="{ showLoader: !showLoader }">
                {{ errorMessage }}
                <div class="inner one"></div>
                <div class="inner two"></div>
                <div class="inner three"></div>
            </div>
            <UploadBtn v-on:selectFile="handleAnalysis" />
            <Selection @selection="changePattern" v-if="this.showPatternView" :options="patternOptions" />
            <Selection @selection="changeComponent" v-if="showPatternView" :options="componentOptions" />
        </section>

        <FlightSummary class="dataModule" :flightTimeline="this.flightTimeline" @pointClicked="changePoint" v-if="showSummary" />
        
        <PatternView :overviewData="this.overviewData" :componentData="this.componentData" :analysis="this.analysis" :componentRating="this.componentRating" :componentFeedback="componentFeedback" :showOverview="this.showOverview" :showComponent="this.showComponent" class="dataModule" v-if="this.showPatternView" />

        <TakeoffView class="dataModule" v-if="showTakeoffView" />

        <LandingView class="dataModule" v-if="showLandingView" />

        <TouchnGoView class="dataModule" v-if="showTouchnGoView" />

        <FlightTimeline :flightTimeline="this.flightTimeline" @pointClicked="changePoint" id="flightTimeline" />
    </section>
</template>

<script>
/* eslint-disable */
import Navbar from '../components/Navbar.vue'
import Selection from '../components/Analysis/Selection.vue'
import UploadBtn from '../components/Analysis/UploadBtn.vue'
import RatingView from '../components/Analysis/RatingView.vue'

import FlightSummary from '../components/Analysis/FlightSummary.vue'

import PatternView from '../components/Analysis/Patterns/PatternView.vue'
import TakeoffView from '../components/Analysis/Takeoffs/TakeoffView.vue'
import LandingView from '../components/Analysis/Landings/LandingView.vue'
import TouchnGoView from '../components/Analysis/TouchnGos/TouchnGoView.vue'

import FlightTimeline from '../components/Analysis/FlightTimeline.vue'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { query, collection, getDocs, doc, getDoc, setDoc } from 'firebase/firestore'

import { db } from '../firebase/config.js'

export default {
    name: "Analysis",
    components: {
        Navbar,
        Selection,
        UploadBtn,
        RatingView,
        FlightSummary,
        PatternView,
        TakeoffView,
        LandingView,
        TouchnGoView,
        FlightTimeline
    },
    data() {
        return {
            downloadUrl: null,
            parsedData: null,
            showOverview: false,
            showSummary: false,
            showComponent: false,
            showPatternView: false,
            showTakeoffView: false,
            showLandingView: false,
            showTouchnGoView: false,
            responseAnalysis: [],
            responseData: [],
            patternOptions: [],
            locationOptions: [],
            componentOptions: [
                { option: 'Overview', pretext: '', value: 'Overview' },
                { option: 'Upwind', pretext: '', value: 'Upwind' },
                { option: 'Crosswind', pretext: '', value: 'Crosswind' },
                { option: 'Downwind', pretext: '', value: 'Downwind' },
                { option: 'Base', pretext: '', value: 'Base' },
                { option: 'Final', pretext: '', value: 'Final' },
                // { option: 'Climb', pretext: '', value: 'Climb' },
                // { option: 'Descent', pretext: '', value: 'Descent' }
            ],
            overviewData: {},
            upwindData: {},
            xwData: {},
            dwData: {},
            baseData: {},
            finalData: {},
            climbData: {},
            descentData: {},
            analysisData: [],
            analysis: [],
            componentType: 'Overview',
            componentData: {},
            showLoader: false,
            errorMessage: null,
            componentRating: 0,
            componentFeedback: [],
            overallRating: 0,
            patternRating: 0,
            flightTimeline: [],
        }
    },
    methods: {
        async handleAnalysis(file) {
            if (!file) return // if no file was selected
            this.showLoader = true
            let uploadResponse = await this.uploadToFirebaseStorage(file);
            let downloadUrl = await uploadResponse; // wait for response and assign to downloadUrl before parsing
            this.downloadUrl = downloadUrl;
            this.parseFile(file);
        },
        async uploadToFirebaseStorage(file) {
            const uid = this.$store.state.user.uid
            let downloadUrl = ``;
            const storage = getStorage();
            const date = new Date();
            const fileName = `${date.getTime()}_${file.name}`;
            const storageRef = ref(storage, `tracks/${uid}/${fileName}`);

            //* csv data upload to cloud storage 
            return new Promise(function(resolve, reject) {
                uploadBytes(storageRef, file)
                    .then(() => {
                        getDownloadURL(ref(storage, `tracks/${uid}/${fileName}`))
                            .then(url => {
                                downloadUrl = url;
                                resolve(downloadUrl);
                            })
                            .catch(error => {
                                reject(error);
                            });
                    });
            });
        },
        async parseFile(file) {
            this.$papa.parse(file, {
                download: true,
                header: false,
                dynamicTyping: true,
                complete: (results) => {
                    this.handleParsed(results.data);
                }
            })
        },
        async handleParsed(fltData) {
            let tailNumber = fltData[0,1][1];
            let airport = fltData[0,1][2];
            let startTime = fltData[0,1][8];
            let endTime = fltData[0,1][9];
            let dataSource = fltData[0,1][17];

            fltData.shift(); // Deletes row 1
            fltData.shift(); // Deletes row 2
            fltData.shift(); //* Deletes row 3, I have no idea why 3rd row also needs to be deleted
            fltData.pop(); // Deletes last row, also no clue why this is needed lol (for some reason null in last row)

            // for (let i = 0; i < fltData.length; i++) { // Fill in null values with 0
            //     for (let j = 0; j < fltData[i].length; j++) {
            //         if (fltData[i][j] === null) {
            //             console.log('null value found at ' + i + ' ' + j);
            //             fltData[i][j] = 0;
            //         }
            //     }
            // }

            const serverResponse = await this.getGrade(fltData);
            // console.log(serverResponse);
            
            await this.$store.dispatch('clearAnalysis'); // clear existing analysis data in local storage

            this.patternOptions = [];
            this.locationOptions = [];
            this.responseAnalysis = [];
            this.responseData = [];

            let airportInfo = [];
            let timelineLabels = [];
            let totalPatterns = 0;
            serverResponse.forEach(dataSet => {
                timelineLabels.push({ label: dataSet.gradeLabel, rating: dataSet.averages.total.rating });
                // Set Location Options
                airportInfo.push(dataSet.airportInfo);
                if (dataSet.patterns) {       
                    dataSet.patterns.forEach(pattern => {
                        totalPatterns++;
                    });
                }
            });

            this.setTimeline(timelineLabels);
            
            // Aggregate Averages
            let avgArr = [];
            let avgObj = {};
            let firstPatternSet = serverResponse[0];
            for (var flightPhase in firstPatternSet.averages) {
                avgObj[flightPhase] = {};
                for (var averageType in firstPatternSet.averages[flightPhase]) {
                    avgArr = [];
                    let numPatterns = 0;
                    for (var patternSet in serverResponse) {
                        avgArr.push(serverResponse[patternSet].averages[flightPhase][averageType] * serverResponse[patternSet].patterns.length);
                        numPatterns += serverResponse[patternSet].patterns.length;
                    }
                    // Calculate Mean of avgArr
                    avgObj[flightPhase][averageType] = Number((avgArr.reduce((a, b) => a + b, 0) / numPatterns).toFixed(3));
                }
            }

            const extraData = {
                flightStats: avgObj,
                tailNumber: tailNumber,
                airport: airport,
                startTime: startTime,
                endTime: endTime,
                dataSource: dataSource,
                instructorID: null,
                remarks: null,
                downloadURL: this.downloadUrl,
                totalPatterns: totalPatterns,
            }

            try {
                await this.$store.dispatch('addAnalysis', {
                    serverResponse: serverResponse,
                    extraData: extraData,
                    uid: this.$store.state.user.uid,
                });
                // await this.changePatternSet(1);
                // await this.setAirportOptions(airportInfo);
                this.showLoader = false;
            } catch (error) {
                console.error(error);
            }

            this.showSummary = true;
        },
        async getGrade(track) {
            return new Promise(async (resolve, reject) => {
                let body = JSON.stringify({
                    track: track,
                });
                try {
                    // const analysisResponse = await fetch(`https://us-central1-peakflights-testing.cloudfunctions.net/api/analysis`, {
                    const analysisResponse = await fetch(`https://us-central1-peakflights-dev.cloudfunctions.net/api/analysis`, {
                    // const analysisResponse = await fetch(`http://localhost:5001/peakflights-dev/us-central1/api/analysis`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: body,
                    });
                    await analysisResponse.json().then(response => {
                        resolve(JSON.parse(response));
                    });
                } catch (error) {
                    this.showLoader = false;
                    this.errorMessage = error;
                    console.error(error);
                    reject(error);
                }
            });
        },
        async setTimeline(timelineLabels) {
            this.flightTimeline = [];
            if (timelineLabels) {
                let index = 0;
                timelineLabels.forEach(timelinePoint => {
                    this.flightTimeline.push({ index: index, name: timelinePoint.label, rating: timelinePoint.rating });
                    index++;
                });
            } else { // If the data is not passed through (indicating this is retrieving from the state, not from newly uploaded data)

                const currentStored = this.$store.state.currentAnalysisData
                if (!currentStored) { // If there is no stored data in the state, exit function
                    return;
                } else {
                    const serverResponse = currentStored.serverRes;
                    let timelineLabels = [];
                    serverResponse.forEach(dataSet => {
                        timelineLabels.push({ label: dataSet.gradeLabel, rating: dataSet.averages.total.rating })
                    });
                    let index = 0;
                    timelineLabels.forEach(timelinePoint => {
                        this.flightTimeline.push({ index: index, name: timelinePoint.label, rating: timelinePoint.rating });
                        index++;
                    });
                    // console.log(this.flightTimeline);
                }
            }
        },
        async changePoint(indexSelected) {
            // console.log('indexSelected: ', indexSelected);
            const currentStored = this.$store.state.currentAnalysisData
            if (!currentStored) { // If there is no stored data in the state, exit function
                return;
            }
            const serverRes = currentStored.serverRes;
            const displayedPoint = serverRes[indexSelected];
            // console.log(displayedPoint.gradeType);
            this.showSummary = false;
            this.showPatternView = false;
            this.showTakeoffView = false;
            this.showLandingView = false;
            this.showTouchnGoView = false;
            switch (displayedPoint.gradeType) {
                case "patterns":
                    this.showPatternView = true;
                    // console.log(indexSelected);
                    this.changePatternSet(indexSelected);
                    break;
                case "takeoff":
                    this.showTakeoffView = true;
                    break;
                case "landing":
                    this.showLandingView = true;
                    break;
                case "touchnGo":
                    this.showTouchnGoView = true;
                    break;
            }
        },
        async changePatternSet(setNumber) {

            // Retrieve data from local storage
            const currentStored = this.$store.state.currentAnalysisData; // Retrieve current stored data
            if (!currentStored) { // If there is no stored data in the state, exit function
                return;
            }
            const extra = currentStored.extra;

            const patternSet = currentStored.serverRes;

            // Get correct pattern set from data
            const patterns = patternSet[setNumber].patterns;

            const averages = patternSet[setNumber].averages;
            
            this.overallRating = averages.total.rating;

            let numPatterns = 0;

            this.responseAnalysis = [];
            this.responseData = [];

            patterns.forEach(pattern => {
                // console.log(pattern)
                numPatterns++;
                let upwindGrade = pattern.upwind.grade;
                let xwGrade = pattern.xw.grade;
                let dwGrade = pattern.dw.grade;
                let baseGrade = pattern.base.grade;
                let finalGrade = pattern.final.grade;
                let patternGrade = pattern.overview.grade;

                let upwindData = pattern.upwind.data;
                let xwData = pattern.xw.data;
                let dwData = pattern.dw.data;
                let baseData = pattern.base.data;
                let finalData = pattern.final.data;

                let overviewData = pattern.overview.data;
                
                this.responseAnalysis.push({upwindGrade, xwGrade, dwGrade, baseGrade, finalGrade, patternGrade});
                this.responseData.push({upwindData, xwData, dwData, baseData, finalData, overviewData});
            });
            
            await this.setPatternOptions(numPatterns);
            await this.changePattern(1);
        },
        async setPatternOptions(numPatterns) {
            this.patternOptions = [];
            if (numPatterns) { // If the number of patterns is passed through
                for (let i = 0; i < numPatterns; i ++) {
                    this.patternOptions.push({ option: (i+1), pretext: "Pattern", value: (i+1) }); // Update number of options
                }
            } else { // If the number of patterns is not passed through (indicating this is retrieving from the state, not from newly uploaded data)
                const currentStored = this.$store.state.currentAnalysisData
                if (!currentStored) { // If there is no stored data in the state, exit function
                    return;
                } else {
                    const data = currentStored.data;
                    for (let i = 0; i < data.length; i ++) {
                        this.patternOptions.push({ option: (i+1), pretext: "Pattern", value: (i+1) }); // Update number of options
                    }
                }
            }
        },
        async changePattern(patternNumber) {

            patternNumber--;
            // console.log(patternNumber);
            this.patternSelected = patternNumber; // used to update DOM

            const data = this.responseData[patternNumber];
            const analysis = this.responseAnalysis[patternNumber];
            
            // console.log(this.responseData[patternNumber]);

            const overviewIdx = data.overviewData.actual.findIndex((obj) => obj.type == 'Track')

            this.overviewData = data.overviewData.actual[overviewIdx];

            // console.log(this.overviewData);

            this.upwindData = data.upwindData;
            this.xwData = data.xwData;
            this.dwData = data.dwData;
            this.baseData = data.baseData;
            this.finalData = data.finalData;

            // console.log(analysis[patternNumber]);
            this.patternRating = analysis.patternGrade;

            this.changeComponent(this.componentType);
        },
        async changeComponent(componentSelected) {
            const patternNumber = this.patternSelected;

            const data = this.responseData[patternNumber];
            const analysis = this.responseAnalysis[patternNumber];

            // console.log(data);
            if (componentSelected !== "Overview") {
                this.showComponent = true;
                this.showOverview = false;
            }

            switch(componentSelected) {
                case("Upwind"):
                    this.componentData = data.upwindData;
                    this.analysis = analysis.upwindGrade;
                    break;
                case("Crosswind"):
                    this.componentData = data.xwData;
                    this.analysis = analysis.xwGrade;
                    break;
                case("Downwind"):
                    this.componentData = data.dwData;
                    this.analysis = analysis.dwGrade;
                    break;
                case("Base"):
                    this.componentData = data.baseData;
                    this.analysis = analysis.baseGrade;
                    break;
                case("Final"):
                    this.componentData = data.finalData;
                    this.analysis = analysis.finalGrade;
                    break;
                case("Overview"):
                    this.showComponent = false;
                    this.showOverview = true;
                    break;
            }
            // console.log(this.componentData);
            this.componentType = componentSelected;
            this.componentRating = analysis.patternGrade;
        },
    },
    created() {
        // this.changePatternSet(1); // Used to handle reloading analysis data when coming back to the page
        // this.setAirportOptions(null);
        this.showSummary = true;
        this.showPatternView = false;
        this.showOverview = false;

        this.showTakeoffView = false;
        this.showLandingView = false;
        this.showTouchnGoView = false;
        this.setTimeline(null);
    }
};
</script>

<style scoped>
/* eslint-disable */

#full {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 1vh;
    margin: 1rem;
    height: 90vh;
}

#interactions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    grid-column: 1 / span 1;
    height: 100%;
    /* border: var(--borderColor); */
}

.viewContainer {
    border: var(--borderColor);
    display: flex;
    flex-direction: column;
    height: 28.75vh;
    box-shadow: var(--boxShadow);
    background-color: var(--secondaryBg);
    border-radius: 15px;
}

#flightTimeline {
    grid-column: span 1;
    grid-row: 2 / span 2;
    outline: #EFEFFA solid 1px;
    height: 100%;
}

.dataModule {
    grid-column: 2 / span 5;
    grid-row: 1 / span 3;
    outline: #EFEFFA solid 1px;
}

/* Loading Animation */
/* -------------------------------------- */
.loader {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;  
}

.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid #EFEFFA;
}

.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid #EFEFFA;
}

.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid #EFEFFA;
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

.showLoader {
    display: none;
}
/* ----------------------------------------------- */
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    #full {
        height: 80vh;
        margin: 1vh;
    }

    .viewContainer {
        height: 25.5vh;
    }
}
</style>