<!-- eslint-disable -->
<template>
    <section>
        <h2>Overall:</h2>
        <h3>{{ overallRating.toString() }}</h3>
        <h2>This Pattern:</h2>
        <h3>{{ patternRating.toString() }}</h3>
    </section>
</template>

<script>
/* eslint-disable */
export default {
    name: "RatingView",
    props: {
        overallRating: {
            type: Number,
            default: 0
        },
        patternRating: {
            type: Number,
            default: 0
        },
    }
}
</script>

<style scoped>
h2, h3 {
    margin: .25em 0;
}
h3 {
    font-size: 2.5em;
}
</style>