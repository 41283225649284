<!-- eslint-disable prettier/prettier !-->
<template>
    <div class="chartContainer">
        <div class="apexChart">
            <apexchart ref="chart" height="100%" width="100%" :options="options" :series="series"></apexchart>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import VueApexCharts from 'vue3-apexcharts';
import { defaultOptions } from '../../charts/options.js';

export default {
    name: "PatternRadar",
    props: {
        series: {
            type: Array,
            required: true
        },
        titleText: {
            type: String,
            default: ''
        },
        radarSize: {
            type: Number,
            default: 150
        },
    },
    data() {
        return {
            options: {
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    },
                    dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radar: {
                        polygons: {
                            strokeColors: '#e9e9e9',
                            strokeWidth: 0.5,
                            connectorColors: '#404040',
                        }
                    }
                },
                stroke: {
                    width: 2
                },
                fill: {
                    opacity: 0.25,
                },
                legend: {
                    floating: true,
                },
                colors: ['#38b6ff', '#ff752b'],
                markers: {
                    size: 3,
                    colors: ['#fff'],
                    strokeColor: '#FF4560',
                    strokeWidth: 1,
                },
                tooltip: {
                    theme: 'dark',
                    onDatasetHover: {
                        highlightDataSeries: true
                    },
                    y: {
                        formatter: function(val) {
                            return val
                        }
                    },
                    x: {
                        show: false
                    },
                    marker: {
                        show: false
                    }
                },
                xaxis: {
                    categories: ['UWD', 'XWD', 'DWD', 'BSE', 'FNL'],
                },
                yaxis: {
                    tickAmount: 1,
                    min: 0,
                    max: 10,
                    show: false
                }
            },
        }
    },
}

</script>

<style scoped>
/* eslint-disable */
.chartContainer {
    height: 90%;
    width: 90%;
    display: flex;
    align-items: center;
}

.apexChart {
    height: 100%;
    width: 100%;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .apexChart {
        height: 100%;
    }
}
</style>
