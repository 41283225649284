<!-- eslint-disable prettier/prettier !-->
<template>
    <select @change="emitSelection">
        <option v-for="option in options" :key="option.option" :value="option.value">
            {{ `${option.pretext} ${option.option}` }}
        </option>
    </select>
</template>

<script>
/* eslint-disable */

export default {
    name: "Selection",
    props: {
        options: {
            type: Array,
            required: true
        },
    },
    methods: {
        emitSelection(event) {
            this.$emit('selection', event.target.value)
        }
    }
}
</script>

<style scoped>
/* eslint-disable */
*,
*::before,
*::after {
  box-sizing: border-box;
}
select {
    /* A reset of styles, including removing the default dropdown arrow */
    appearance: none;
    /* Additional resets for further consistency */
    border: none;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    background-color: var(--secondaryBg);
    color: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 75%;
    font-size: 1.5rem;

    -webkit-box-shadow: var(--boxShadow);
    box-shadow: var(--boxShadow);
    text-align: center;
}
select::-ms-expand {
  display: none;
}
</style>