<!-- eslint-disable -->
<template>
	<HomeNav />
	<section id="login">
		<form id="loginForm" @submit.prevent="signupSubmit">
			<p>Already have an account?</p>
			<router-link to="/login">Go to Login</router-link>
			<input type="text" name="name" placeholder="Full Name" v-model="name" required>
			<input type="email" name="email" placeholder="Email" v-model="email" required>
			<select name="license" id="license" v-model="license">
				<option value="">Type of License</option>
				<option value="Student">Student</option>
				<option value="Sport">Sport</option>
				<option value="Recreational">Recreational</option>
				<option value="Private">Private</option>
				<option value="Instrument">Instrument</option>
				<option value="Commercial">Commercial</option>
				<option value="CFI">CFI</option>
				<option value="ATP">ATP</option>
				<option value="CFI + ATP">CFI + ATP</option>
			</select>
			<input type="text" name="hours" id="hours" placeholder="Hours" v-model="hours">
			<input type="password" name="password" placeholder="Create a Password" v-model="password" required>
			<AuthBtn btnText="Sign Up" />
		</form>
	</section>
	<p v-if="errorMessage" id="errorMessage" style="color: red;">{{ errorMessage }}</p>
</template>

<script>
/* eslint-disable */
import AuthBtn from '../components/AuthBtn.vue'
import HomeNav from '../components/HomeNav.vue'

import { auth } from '../firebase/config.js'
import { updateProfile } from 'firebase/auth'

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
	name: "Signup",
	components: {
		AuthBtn,
		HomeNav
	},
	setup() {
		const email = ref('')
		const password = ref('')
		const name = ref('')
		const license = ref('')
		const hours = ref('')
		const errorMessage = ref(null)

		const store = useStore()
		const router = useRouter()

		const signupSubmit = async () => {
			try {
				await store.dispatch('signup', {
					email: email.value,
					password: password.value,
					name: name.value,
					license: license.value,
					hours: hours.value
				}); // Adds user auth to store and to firebase
				await updateProfile(auth.currentUser, {
					displayName: name.value
				}); // Changes auth profile with correct displayName (working)
				router.push('/dashboard');
			} catch (error) {
				let errorString
				const errorCode = error.code;
				switch (errorCode) {
					case "auth/email-already-in-use":
						errorString = "Email already in use";
						break;
					case "auth/invalid-email":
						errorString = "Invalid email";
						break;
					case "auth/operation-not-allowed":
						errorString = "Operation not allowed";
						break;
					case "auth/weak-password":
						errorString = "Weak password (Please choose a password with at least 6 characters)";
						break;
				}
				errorMessage.value = errorString;
			}
		}

		return { signupSubmit, email, password, name, license, hours, errorMessage }
	},
}
</script>


<style scoped>

#login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	width: 80%;
	margin: auto;
}

#loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--secondaryBg);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#loginForm input, #license {
    width: 80%;
    height: 40px;
    border: none;
    border-bottom: 1px solid var(--border);
    background-color: var(--tertiaryBg);
    color: white;
    font-size: 1.2em;
    padding: 0 10px;
    margin-bottom: 10px;
}

#loginForm input:focus {
    outline: none;
}

#loginForm button {
    width: 60%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: var(--btnColor);
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 0.25em;
}

#loginForm button:hover {
    background-color: var(--btnHover);
}

a {
    text-decoration: none;
    color: var(--title);
    margin-bottom: 0.5em;
}

a:hover {
    color: var(--btnHover);
}

input, select {
	display: block;
}
</style>