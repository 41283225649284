<!-- eslint-disable prettier/prettier !-->
<template>
    <section>
        <div class="apexChart">
            <apexchart ref="chart" height="100%" width="100%" :options="options" :series="series"></apexchart>
        </div>
    </section>
</template>

<script>
/* eslint-disable */
import VueApexCharts from 'vue3-apexcharts';
import { defaultOptions } from '../../charts/options.js';

export default {
    name: "RatingGraph",
    props: {
        series: {
            type: Array,
            required: true
        },
        axisLabels: {
            type: Array,
            required: false,
            default: ['1 Flight Ago', '2 Flights Ago', '3 Flights Ago', '4 Flights Ago', '5 Flights Ago', '5 Flights Ago', '6 Flights Ago', '7 Flights Ago', '8 Flights Ago', '9 Flights Ago', '10 Flights Ago']
        },
    },
    data() {
        return {
            options: {
                chart: {
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#38b6ff', '#ff752b'],
                dataLabels: {
                    // enabled: true
                },
                stroke: {
                    width: [3, 3],
                    curve: 'smooth'
                },
                grid: {
                    // show: false,
                    borderColor: '#5c5c5c',
                    strokeDashArray: 0,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                labels: ['10 Flights Ago', '9 Flights Ago', '8 Flights Ago', '7 Flights Ago', '6 Flights Ago', '5 Flights Ago', '4 Flights Ago', '3 Flights Ago', '2 Flights Ago', '1 Flight Ago'],
                markers: {
                    size: 5,
                    strokeWidth: 0,
                    hover: {
                        size: 6
                    }
                },
                tooltip: {
                    enabled: true,
                    theme: 'dark',
                },
                yaxis: {
                    show: true,
                    tickAmount: 4,
                    max: 10,
                    // forceNiceScale: true,
                    labels: {
                        show: true,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            colors: '#8e8da4',
                        },
                    },
                },
                xaxis: {
                    // type: 'datetime',
                    labels: {
                        show: true
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        show: false
                    },
                    tooltip: {
                        enabled: false
                    },
                },
            },
        }
    },
}

</script>

<style scoped>
/* eslint-disable */

.apexChart {
    height: 100%;
    width: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .apexChart {
        height: 100%;
    }
}
</style>
