<!-- eslint-disable prettier/prettier !-->
<template>
    <div class="apexChart">
        <apexchart ref="chart" :height="chartHeight" width="100%" type="line" :options="updateChart" :series="series"></apexchart>
    </div>
</template>

<script>
/* eslint-disable */
import VueApexCharts from 'vue3-apexcharts';
import defaultOptions from '../../../../charts/options.js';

export default {
    name: "ComponentChart",
    props: {
        series: {
            type: Array,
            required: true
        },
        titleText: {
            type: String,
            default: ''
        },
        chartHeight: {
            type: String,
            default: '100%' // using % seems to break this for some reason
        },
        lineColor: {
            type: String,
            default: '#00ff91'
        },
        dataType: {
            type: Object,
            default: {
                label: 'ft',
                type: 'Altitude'
            }
        },
        axisMins: {
            type: Object,
        },
    },
    computed: {
        updateChart() {
            const defaults = defaultOptions.defaultOptions;
            // console.log(defaults)

            const curveArr = [];
            const colorsArr = [];
            const widthArr = [];
            const dashArr = [];
            const sizeArr = [];
            for (let i = 0; i < this.series.length; i++) {
                if (!this.series[i].name.includes("ideal")) {
                    curveArr.push('straight');
                    if (this.series[i].name.includes("good")) {
                        colorsArr.push(this.lineColor);
                    } else {
                        colorsArr.push('#ff5145');
                    }
                    widthArr.push(2);
                    dashArr.push(0);
                    sizeArr.push(0);    
                }
            }
            for (let i = 0; i < 4; i++) {
                curveArr.push('straight');
                colorsArr.push('#858585');
                widthArr.push(1);
                dashArr.push(5);
                sizeArr.push(0);
            }

            const chartOptions = {...defaults, ...{
                xaxis: {
                    min: this.axisMins.x.min,
                    max: this.axisMins.x.max,
                    type: 'numeric',
                    ...defaults.xaxis,
                },
                yaxis: {
                    min: () => this.axisMins.y.min,
                    max: () => this.axisMins.y.max,
                    labels: {
                        formatter: (val) => `${Math.round(val)}${this.dataType.label}`,
                    },
                    ...defaults.yaxis,
                },
                title: {
                    text: `${this.titleText}`,
                    ...defaults.title,
                },
                stroke: {
                    curve: curveArr,
                    colors: colorsArr,
                    width: widthArr,
                    dashArray: dashArr,
                    ...defaults.stroke,
                },
                markers: {
                    size: sizeArr,
                    ...defaults.markers,
                },
                tooltip: {
                    enabledOnSeries: [0],
                    y: {
                        formatter: (val) => `${val}${this.dataType.label}`,
                        title: {
                            formatter: () => `${this.dataType.type}:`,
                        }
                    },
                    ...defaults.tooltip,
                }
            }}

            return chartOptions;
        },
    },
}

</script>

<style scoped>
/* eslint-disable */
.apexChart {
    height: 100%;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .apexChart {
        height: 100%;
    }
}
</style>
