<!-- eslint-disable prettier/prettier !-->
<template>
    <section id="dataView">
        <section id="sidebar">
            <DataInfo v-if="footnoteData" :data="footnoteData" />
            <div id="dataSelection">
                <h3>Data Type:</h3>
                <Selection id="selection" @selection="changeData" :options="dataOptions" />
            </div>
        </section>
        <section id="chartSection">
            <!-- {{ this.selectedData }} -->
            <ComponentChart id="chart" chartHeight="100%" :series="updateData" :dataType="dataType" lineColor="#40ff66" :axisMins="axisMinMaxes" :titleText="this.selectedData" />
        </section>
    </section>
</template>
<script>
/* eslint-disable */
import Selection from "../../../Selection.vue"
import ComponentChart from "./ComponentChart.vue"
import DataInfo from "./DataInfo.vue"
import { defaultOptions } from '../../../../charts/options.js';

/* eslint-disable */
export default {
    components: { 
        Selection,
        DataInfo,
        ComponentChart,
    },
    name: "DataView",
    data() {
        return {
            selectedData: '',
            dataType: {},
            footnoteData: [],
            selectedIdx: 0,
            idealObj: {
                center: null,
                high: null,
                low: null,
            },
        }
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: null,
        },
        analysis: {
            type: Object,
            required: true
        },
    },
    methods: {
        changeData(selectedData) {
            this.selectedIdx = this.data.findIndex(data => data.type == selectedData)
            this.selectedData = selectedData
            this.dataType = {
                label: this.data[this.selectedIdx].label,
                type: this.data[this.selectedIdx].type,
            }
        }
    },
    computed: {
        dataOptions: function() {
            if (this.data.length > 1) {
                const data = this.data
                const options = []
                data.forEach((parameter) => {
                    options.push({ pretext: parameter.type, option: '' });
                });
                return options
            } else {
                return []
            }
        },
        updateData: function() {
            let series = [];
            if (!this.data[this.selectedIdx]) {
                return series
            }
            let evalArr = this.data[this.selectedIdx].data.actual.eval;
            let data = this.data[this.selectedIdx].data.actual.data;
            let goodSegment = [];
            let goodData = [];
            let badSegment = [];
            let badData = [];
            if (evalArr.length > 0) {
                for (let i = 0; i < evalArr.length - 1; i++) { // loops through eval and creates good and bad segments to be colored in ComponentChart
                    if (evalArr[i] == true) {
                        if (evalArr[i + 1] == true && i != evalArr.length - 2) {
                            goodSegment.push(data[i]);
                        } else {
                            goodSegment.push(data[i]);
                            goodSegment.push(data[i + 1]);
                            goodData.push(goodSegment);
                            goodSegment = [];
                        }
                    } else {
                        if (evalArr[i + 1] == false && i != evalArr.length - 2) {
                            badSegment.push(data[i]);
                        } else {
                            badSegment.push(data[i]);
                            badSegment.push(data[i + 1]);
                            badData.push(badSegment);
                            badSegment = [];
                        }
                    }
                }
            } else {
                goodData.push(data);
            }
            let i = 0;
            goodData.forEach(segment => {
                series.push({
                    name: `good_${i}`,
                    data: segment,
                });
                i++
            });
            badData.forEach(segment => {
                series.push({
                    name: `bad_${i}`,
                    data: segment,
                });
                i++
            });
            if (this.data[this.selectedIdx].data.ideal) {
                this.idealObj = this.data[this.selectedIdx].data.ideal;
                let idealCenter = null;
                let idealHigh = null;
                let idealLow = null;
                
                this.idealObj.center ? idealCenter = this.idealObj.center : null; // has to be 2 item array to remain consistent with actual series
                this.idealObj.high ? idealHigh = this.idealObj.high : null; // has to be 2 item array to remain consistent with actual series
                this.idealObj.low ? idealLow = this.idealObj.low : null; // has to be 2 item array to remain consistent with actual series
                idealCenter !== null ? series.push({
                    name: 'ideal_center',
                    data: idealCenter
                }) : null;
                idealHigh !== null ? series.push({
                    name: 'ideal_high',
                    data: idealHigh
                }) : null;
                idealLow !== null ? series.push({
                    name: 'ideal_low',
                    data: idealLow
                }) : null;
                // console.log(series)
            } else {
                this.idealObj = {
                    center: null,
                    high: null,
                    low: null,
                }
            }
            this.footnoteData = this.analysis.footnoteData
            return series
        },
        axisMinMaxes: function() {
            let axisMinMaxes = {
                x: {
                    min: null,
                    max: null
                },
                y: {
                    min: null,
                    max: null
                }
            }
            if (!this.data[this.selectedIdx]) {
                return axisMinMaxes
            }
            const xVals = this.data[this.selectedIdx].data.actual.data.map(point => point[0]) // x values of actual data
            const yVals = this.data[this.selectedIdx].data.actual.data.map(point => point[1]) // y values of actual data

            const idealHighs = this.idealObj.high // points of ideal high
            const idealLows = this.idealObj.low // points of ideal low
            const idealCenters = this.idealObj.center // points of ideal center

            idealHighs ? idealHighs.forEach(point => { yVals.push(point[1]) }) : null; // if ideal high exists, add to yVals
            idealLows ? idealLows.forEach(point => { yVals.push(point[1]) }) : null; // if ideal low exists, add to yVals
            idealCenters ? idealCenters.forEach(point => { yVals.push(point[1]) }) : null; // if ideal center exists, add to yVals
            
            if (!(this.data[this.selectedIdx].type === 'Track')) {
                axisMinMaxes.x.min = Math.min(...xVals);
                axisMinMaxes.x.max = Math.max(...xVals);
                axisMinMaxes.y.min = Math.min(...yVals);
                axisMinMaxes.y.max = Math.max(...yVals);
            } else {
                let xWindow = Math.max(...xVals) - Math.min(...xVals)
                let yWindow = Math.max(...yVals) - Math.min(...yVals)
                let largestWindow = Math.max(xWindow, yWindow)
                let xCenter = mean(xVals);
                let yCenter = mean(yVals);
                let xMin = xCenter - largestWindow / 2;
                let xMax = xCenter + largestWindow / 2;
                let yMin = yCenter - largestWindow / 2;
                let yMax = yCenter + largestWindow / 2;
                axisMinMaxes = {
                    x: {
                        min: xMin,
                        max: xMax
                    },
                    y: {
                        min: yMin,
                        max: yMax
                    }
                }
            }
            // console.log(axisMinMaxes)
            return axisMinMaxes
        }
    },
}
const mean = xVals => xVals.reduce((a,b) => a + b, 0) / xVals.length;
</script>

<style scoped>
#dataView {
    display: flex;
    flex-direction: row;
    background-color: var(--secondaryBg);
    box-shadow: var(--boxShadow);
    border-radius: 15px;
}

#sidebar {
    width: 25%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
}

#dataSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

#dataSelection h3 {
    margin: 1em;
}

#selection {
    background-color: var(--tertiaryBg);
}

#chart {
    width: 100%;
    height: 100%;
}

#chartSection {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: .75em;
}
</style>