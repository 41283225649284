<!-- eslint-disable prettier/prettier !-->
<template>
	<Navbar headingTxt="" />
	<section id="dashboard">
		<div class="filterModule outline">
			<!-- <div class="selection">
				<Selection class="selection" id="flightFilter" @selection="changeTime" :options="timeFilters" />
			</div> -->
			<h2 id="radarTitle">Your Pattern Component Ratings</h2>
			<PatternRadar id="radar" :series="patternComponentRatings" />
		</div>
		
		<section id="ratingRadial" class="outline">
			<h3 class="radialTitle" id="ratingTitle">PeakFlights Rating</h3>
			<RatingRadial id="ratingGauge" :series="ratings" titleText="PeakFlights Rating" :changeColor="changeColor" :yOffset="radialOffset" :chartHeight="radialHeight" />
			<h2 id="ratingNumber" :class="isRatingGood ? 'greenText' : 'redText'" >
				{{ (ratings[0]/10).toFixed(2) }}
				<i v-if="ratings[0]/10 >= 9" class="fa-solid fa-check-double fa-2xs" style="color: #2bff3d"></i>
				<i v-if="ratings[0]/10 >= 8 && ratings[0]/10 < 9" class="fa-solid fa-check fa-2xs" style="color: #2bff3d"></i>
				<i v-if="ratings[0]/10 < 8 && ratings[0]/10 >= 7" class="fa-solid fa-triangle-exclamation fa-2xs" style="color: yellow"></i>
				<i v-if="ratings[0]/10 < 7" class="fa-solid fa-circle-exclamation fa-2xs" style="color: red"></i>
			</h2>
		</section>

		<RatingGraph id="ratingGraph" class="graph outline" :series="ratingSeries" />

		<div class="filterModule outline">
			<div class="selection">
				<Selection class="selection" id="componentFilter" @selection="changeComponent" :options="componentOptions" />
			</div>

			<!-- <div class="selection">
				<Selection class="selection" id="dataFilter" @selection="changeData" :options="dataOptions" />
			</div> -->

			<h3 class="radialTitle">{{ this.componentTitleText }} Rating</h3>
			<RatingRadial id="compRating" :series="componentRatings" :changeColor="this.componentChangeColor" :yOffset="radialOffset" :chartHeight="radialHeight" />
			<h3 id="compRatingNumber" :class="isComponentGood ? 'greenText' : 'redText'">{{ (componentRatings[0]/10).toFixed(2) }}</h3>
		</div>

		<RatingGraph id="compRatingGraph" class="graph outline" :series="componentDataSeries" />

		<ComponentFeedback :mostImproved="mostImproved" :overallBest="overallBest" :mostConsistent="mostConsistent" :overallWeakest="overallWeakest" class="outline" id="compFeedback" />
	</section>
</template>

<script>
/* eslint-disable */
import Navbar from '../components/Navbar.vue';
import Selection from '../components/Selection.vue';
import PatternRadar from '../components/Dashboard/PatternRadar.vue';
import RatingRadial from '../components/Dashboard/RatingRadial.vue';
import RatingGraph from '../components/Dashboard/RatingGraph.vue';
import ComponentFeedback from '../components/Dashboard/ComponentFeedback.vue';

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { collection, query, where, orderBy, limit, getDocs } from '@firebase/firestore';
import { db } from '../firebase/config';

export default {
	name: 'Dashboard',
	components: {
		Navbar,
		Selection,
		PatternRadar,
		RatingRadial,
		RatingGraph,
		ComponentFeedback
	},
	data() {
		return {
			timeFilters: [
				{
					option: "14 Days",
					value: "14",
					pretext: ""
				},
				{
					option: "30 Days",
					value: "30",
					pretext: ""
				},
				{
					option: "3 Months",
					value: "90",
					pretext: ""
				},
				{
					option: "6 Months",
					value: "180",
					pretext: ""
				},
			],
			componentOptions: [
				{
					"option": "Upwind",
					"value": "upwind",
					"pretext": ""
				},
				{
					"option": "Crosswind",
					"value": "crosswind",
					"pretext": ""
				},
				{
					"option": "Downwind",
					"value": "downwind",
					"pretext": ""
				},
				{
					"option": "Base",
					"value": "base",
					"pretext": ""
				},
				{
					"option": "Final",
					"value": "final",
					"pretext": ""
				},
			],
			componentTitleText: "Upwind",
			dataOptions: [
				{
					option: 'Rating',
					value: 'speed',
					pretext: ''
				},
				{
					option: 'Speed',
					value: 'spd',
					pretext: ''
				}
			],
			patternComponentRatings: [{
                name: 'Current',
                data: [],
            }, {
                name: 'Previous',
                data: [],
            }],
			ratings: [],
			upwindRatings: [[], []],
			crosswindRatings: [[], []],
			downwindRatings: [[], []],
			baseRatings: [[], []],
			finalRatings: [[], []],
			componentRatings: [],
			ratingSeries: [{
				name: 'Current',
				data: []
			}, {
				name: 'Previous',
				data: [],
			}],
			componentDataSeries: [{
				name: 'Current',
				data: []
			}, {
				name: 'Previous',
				data: [],
			}],
			componentChangeColor: 'white',
			mostImproved: {
				name: '',
				direction: '',
				change: 0
			},
			overallBest: {
				name: '',
				value: 0
			},
			mostConsistent: {
				name: '',
				direction: '',
				change: 0
			},
			overallWeakest: {
				name: '',
				value: 0
			},
		}
	},
	created() {
		this.changeTime(14)
	},
	methods: {
		async changeTime(value) {
			// console.log(value)
			const uid = this.$store.state.user.uid
			// let daysRange = value;
			let currentTime = new Date();
			// currentTime.setDate(currentTime.getDate() - daysRange);
			const q = query(collection(db, `users/${uid}/flights`), orderBy('timestamp', 'desc'), limit(10));
			const querySnapshot = await getDocs(q);
			if (querySnapshot.empty) {
				console.log('No matching documents.');
				return;
			}

			let currentRating = [];
			let previousRating = [];
			let itemNumber = 0;
			querySnapshot.forEach((doc) => {
				// console.log(doc.id, '=>', doc.data());
				let data = doc.data();
				let timestamp = data.timestamp.toDate();
				let rating = data.rating;

				if (itemNumber >= 5) {
					this.ratingSeries[1].data.push([10 - itemNumber, rating]);
					previousRating.push(rating);

					this.upwindRatings[1].push(data.averages.upwind.rating);
					this.crosswindRatings[1].push(data.averages.xw.rating);
					this.downwindRatings[1].push(data.averages.dw.rating);
					this.baseRatings[1].push(data.averages.base.rating);
					this.finalRatings[1].push(data.averages.final.rating);
				} else {
					this.ratingSeries[0].data.push([10 - itemNumber, rating]);
					currentRating.push(rating);

					this.upwindRatings[0].push(data.averages.upwind.rating);
					this.crosswindRatings[0].push(data.averages.xw.rating);
					this.downwindRatings[0].push(data.averages.dw.rating);
					this.baseRatings[0].push(data.averages.base.rating);
					this.finalRatings[0].push(data.averages.final.rating);
				}
				if (itemNumber == 5) {
					this.ratingSeries[0].data.push([5, rating]);
				}
				itemNumber++;
			});
			// Average out ratings and push into series for radial graph
			this.ratings.push(((currentRating.reduce((a, b) => a + b, 0) / currentRating.length) * 10).toFixed(1));
			this.ratings.push(((previousRating.reduce((a, b) => a + b, 0) / previousRating.length) * 10).toFixed(1));

			let currentUpwind = ((this.upwindRatings[0].reduce((a, b) => a + b, 0) / this.upwindRatings[0].length)).toFixed(1);
			let currentCrosswind = ((this.crosswindRatings[0].reduce((a, b) => a + b, 0) / this.crosswindRatings[0].length)).toFixed(1);
			let currentDownwind = ((this.downwindRatings[0].reduce((a, b) => a + b, 0) / this.downwindRatings[0].length)).toFixed(1);
			let currentBase = ((this.baseRatings[0].reduce((a, b) => a + b, 0) / this.baseRatings[0].length)).toFixed(1);
			let currentFinal = ((this.finalRatings[0].reduce((a, b) => a + b, 0) / this.finalRatings[0].length)).toFixed(1);

			let previousUpwind = ((this.upwindRatings[1].reduce((a, b) => a + b, 0) / this.upwindRatings[1].length)).toFixed(1);
			let previousCrosswind = ((this.crosswindRatings[1].reduce((a, b) => a + b, 0) / this.crosswindRatings[1].length)).toFixed(1);
			let previousDownwind = ((this.downwindRatings[1].reduce((a, b) => a + b, 0) / this.downwindRatings[1].length)).toFixed(1);
			let previousBase = ((this.baseRatings[1].reduce((a, b) => a + b, 0) / this.baseRatings[1].length)).toFixed(1);
			let previousFinal = ((this.finalRatings[1].reduce((a, b) => a + b, 0) / this.finalRatings[1].length)).toFixed(1);

			this.patternComponentRatings[0].data.push(currentUpwind);
			this.patternComponentRatings[0].data.push(currentCrosswind);
			this.patternComponentRatings[0].data.push(currentDownwind);
			this.patternComponentRatings[0].data.push(currentBase);
			this.patternComponentRatings[0].data.push(currentFinal);

			this.patternComponentRatings[1].data.push(previousUpwind);
			this.patternComponentRatings[1].data.push(previousCrosswind);
			this.patternComponentRatings[1].data.push(previousDownwind);
			this.patternComponentRatings[1].data.push(previousBase);
			this.patternComponentRatings[1].data.push(previousFinal);

			// Calc Most Improved
			let upwindChange = (currentUpwind - previousUpwind).toFixed(2);
			let crosswindChange = (currentCrosswind - previousCrosswind).toFixed(2);
			let downwindChange = (currentDownwind - previousDownwind).toFixed(2);
			let baseChange = (currentBase - previousBase).toFixed(2);
			let finalChange = (currentFinal - previousFinal).toFixed(2);

			let changeArr = [Number(upwindChange), Number(crosswindChange), Number(downwindChange), Number(baseChange), Number(finalChange)];

			let mostImproved = Math.max(...changeArr);

			let mostImprovedIndex = changeArr.indexOf(mostImproved);

			switch (mostImprovedIndex) {
				case 0:
					this.mostImproved.name = 'Upwind';
					break;
				case 1:
					this.mostImproved.name = 'Crosswind';
					break;
				case 2:
					this.mostImproved.name = 'Downwind';
					break;
				case 3:
					this.mostImproved.name = 'Base';
					break;
				case 4:
					this.mostImproved.name = 'Final';
					break;
			}
			this.mostImproved.change = mostImproved;
			if (mostImproved < 0) {
				this.mostImproved.direction = '-';
			} else {
				this.mostImproved.direction = '+';
			}

			// Calc Best
			let currentArr = [Number(currentUpwind), Number(currentCrosswind), Number(currentDownwind), Number(currentBase), Number(currentFinal)];

			let best = Math.max(...currentArr);

			let bestIndex = currentArr.indexOf(best);

			switch (bestIndex) {
				case 0:
					this.overallBest.name = 'Upwind';
					break;
				case 1:
					this.overallBest.name = 'Crosswind';
					break;
				case 2:
					this.overallBest.name = 'Downwind';
					break;
				case 3:
					this.overallBest.name = 'Base';
					break;
				case 4:
					this.overallBest.name = 'Final';
					break;
			}

			this.overallBest.value = best;

			// Calc Most Consistent
			const absoluteValueArray = (array) => {
				return array.map(Math.abs);
			};

			let consistent = Math.min(...absoluteValueArray(changeArr));

			let consistentIndex = changeArr.indexOf(consistent);

			switch (consistentIndex) {
				case 0:
					this.mostConsistent.name = 'Upwind';
					break;
				case 1:
					this.mostConsistent.name = 'Crosswind';
					break;
				case 2:
					this.mostConsistent.name = 'Downwind';
					break;
				case 3:
					this.mostConsistent.name = 'Base';
					break;
				case 4:
					this.mostConsistent.name = 'Final';
					break;
			}

			this.mostConsistent.change = consistent;
			if (consistent < 0) {
				this.mostConsistent.direction = '-';
			} else {
				this.mostConsistent.direction = '+';
			}

			// Calc Worst
			let weakest = Math.min(...currentArr);

			let weakestIndex = currentArr.indexOf(weakest);

			switch (weakestIndex) {
				case 0:
					this.overallWeakest.name = 'Upwind';
					break;
				case 1:
					this.overallWeakest.name = 'Crosswind';
					break;
				case 2:
					this.overallWeakest.name = 'Downwind';
					break;
				case 3:
					this.overallWeakest.name = 'Base';
					break;
				case 4:
					this.overallWeakest.name = 'Final';
					break;
			}

			this.overallWeakest.value = weakest;

			// Initial Upwind Change Call
			this.changeComponent('upwind');
		},
		changeComponent(value) {
			// Clear Existing Rating Array
			this.componentRatings = [];
			// Create Formatted Title Text
			const upper = value.charAt(0).toUpperCase()
			const rest = value.slice(1)
			const title = `${upper}${rest}`
			this.componentTitleText = title
			
			// Get Index for Correct Component and Set Ratings Array
			let idx = null;
			let ratingsArray = null;
			if (value == 'upwind') {
				idx = 0;
				ratingsArray = this.upwindRatings;
			} else if (value == 'crosswind') {
				idx = 1;
				ratingsArray = this.crosswindRatings;
			} else if (value == 'downwind') {
				idx = 2;
				ratingsArray = this.downwindRatings;
			} else if (value == 'base') {
				idx = 3;
				ratingsArray = this.baseRatings;
			} else if (value == 'final') {
				idx = 4;
				ratingsArray = this.finalRatings;
			}

			this.componentRatings.push(this.patternComponentRatings[0].data[idx] * 10);
			this.componentRatings.push(this.patternComponentRatings[1].data[idx] * 10);

			// Format Data for Line Graph
			this.componentDataSeries[1].data = [];
			this.componentDataSeries[0].data = [];
			let index = 5;
			ratingsArray[1].forEach((rating) => {
				this.componentDataSeries[1].data.push([10 - index, rating.toFixed(2)]);
				index++;
			});
			index = 0;
			ratingsArray[0].forEach((rating) => {
				this.componentDataSeries[0].data.push([ 10 - index, rating.toFixed(2)]);
				index++;
			});
			this.componentDataSeries[0].data.push([5, ratingsArray[0][ratingsArray.length - 1].toFixed(2)]); // Add connecting point

			// Set Rating Change Color
			if (this.componentRatings[0] > this.componentRatings[1]) {
				this.componentChangeColor = '#52ff2b';
			} else {
				this.componentChangeColor = '#ff4e2b';
			}
		},
		changeData(value) {
			console.log(value)
		},
	},
	computed: {
		changeColor() {
			if (this.ratings[0] > this.ratings[1]) {
				return '#52ff2b';
			} else {
				return '#ff4e2b';
			}
		},
		isRatingGood() {
			return this.ratings[0]/10 > 8
		},
		isComponentGood() {
			return this.componentRatings[0]/10 > 8
		},
		radialHeight() {
			if (screen.width > 1200) {
				return '400px';
			} else {
				return '300px';
			}
		},
		radialOffset() {
			if (screen.width > 1200) {
				return '-80';
			} else {
				return '200';
			}
		},
	}
}
</script>

<style>
h2, h3 {
    margin: 0;
	text-align: center;
}

#radarTitle {
	text-align: center;
	margin: 1em;
	margin-bottom: 0em;
}

#ratingTitle {
	margin-bottom: 0;
	font-size: 1.5em;
}

#dashboard {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 44vh);
	grid-gap: 1em;
	margin: 1em;
}

.filterModule {
	grid-column: 1 / span 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#ratingGauge {
	grid-column: 2 / span 1;
	grid-row: 1 / span 1;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	height: 67%;
	margin: 0;
}

#ratingNumber {
	font-size: 4em;
	font-weight: bold;
}

#compRating {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

#compRatingNumber {
	font-size: 3em;
	font-weight: bold;
}

.radial {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin: 0;
}

.radialTitle {
	margin-top: 1em;
}

.graph {
	grid-column: span 3;
	display: flex;
	flex-direction: column;
}

.outline {
	box-shadow: var(--boxShadow);
	background-color: var(--secondaryBg);
	border-radius: 15px;
}

.selection {
	margin-top: 0.5em;
	width: 75%;
	outline: 2px solid var(--tertiaryBg);
}

#flightFilter, #componentFilter, #dataFilter {
	margin: 0.15em;
	width: 100%;
}

.greenText {
    color: #38ff74;
}

.redText {
	color: #ff4e2b;
}

@media screen and (max-width: 1200px) {
	#dashboard {
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: repeat(2, 39vh);
	}

	#radar {
		grid-column: 1 / span 1;
		grid-row: 1 / span 1;
	}

	#ratingGauge {
		/* transform: translateY(-40px); */
	}

	#ratingNumber {
		font-size: 3.25em;
	}

	#compRatingNumber {
		/* transform: translateY(-100px); */
	}
	
}

</style>