<!-- eslint-disable -->
<template>
    <h2>Administrator Page</h2>
    <section id="airportAdder">

    </section>
    <section id="feedbackData">
        <button @click="this.showFeedback = !this.showFeedback">Add Feedback</button>
        <section id="addFeedback" v-show="showFeedback">
            <table>
                <tr>
                    <th>Phase</th>
                    <th>Component</th>
                    <th>Parameter</th>
                    <th>Specific Param</th>
                    <th>Nature</th>
                    <th>Comment</th>
                </tr>
                <tr>
                    <td>
                        <select name="option">
                            <option v-for="option in codeRef.phaseOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
                        </select>
                    </td>
                    <td>
                        <select name="component">
                            <option v-for="option in codeRef.componentOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
                        </select>
                    </td>
                    <td>
                        <select name="param">
                            <option v-for="option in codeRef.parameterOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
                        </select>
                    </td>
                    <td>
                        <select name="specific">
                            <option v-for="option in codeRef.specificOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
                        </select>
                    </td>
                    <td>
                        <select name="component">
                            <option v-for="option in codeRef.natureOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
                        </select>
                    </td>
                    <td><input type="text"></td>
                </tr>
            </table>
            <button>Add</button>
        </section>
    </section>
    <section id="administrators">
        <button @click="this.showAdminAdd = !this.showAdminAdd">Add Administrator</button>
        <section id="addAdmin" v-show="showAdminAdd">
            <table>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                </tr>
                <tr>
                    <td><input type="text"></td>
                    <td><input type="text"></td>
                </tr>
            </table>
            <button>Add</button>
            <button>Remove</button>
        </section>
    </section>
    <section id="userSearch">
        <button @click="this.showUserSearch = !this.showUserSearch">Search Users</button>
        <section id="users" v-show="showUserSearch">
            <table>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                </tr>
                <tr>
                    <td><input type="text"></td>
                    <td><input type="text"></td>
                </tr>
            </table>
        </section>
    </section>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Admin',
    data() {
        return {
            showFeedback: false,
            showAdminAdd: false,
            showUserSearch: false,
            codeRef: {
                phaseOptions: [
                    {
                        value: 0,
                        text: 'Patterns'
                    },
                    {
                        value: 1,
                        text: 'Maneuvers'
                    },
                    {
                        value: 2,
                        text: 'Approaches'
                    }
                ],
                componentOptions: [
                    {
                        value: 0,
                        text: 'Upwind'
                    },
                    {
                        value: 1,
                        text: 'Crosswind'
                    },
                    {
                        value: 2,
                        text: 'Downwind'
                    },
                    {
                        value: 3,
                        text: 'Base'
                    },
                    {
                        value: 4,
                        text: 'Final'
                    },
                    {
                        value: 5,
                        text: 'Climbout'
                    },
                    {
                        value: 6,
                        text: 'Descent'
                    },
                ],
                parameterOptions: [
                    {
                        value: 0,
                        text: 'Altitude'
                    },
                    {
                        value: 1,
                        text: 'Course'
                    },
                    {
                        value: 2,
                        text: 'Airspeed'
                    },
                    {
                        value: 3,
                        text: 'Bank'
                    },
                    {
                        value: 4,
                        text: 'Pitch'
                    },
                    {
                        value: 5,
                        text: 'Vertical Speed/Descent Rate'
                    },
                    {
                        value: 6,
                        text: 'Distance'
                    }
                ],
                specificOptions: [
                    {
                        value: 0,
                        text: 'None'
                    },
                    {
                        value: 1,
                        text: 'Turnout Altitude'
                    },
                    {
                        value: 2,
                        text: 'Descent Angle'
                    }
                ],
                natureOptions: [
                    {
                        value: 0,
                        text: 'None'
                    },
                    {
                        value: 1,
                        text: 'Too High'
                    },
                    {
                        value: 2,
                        text: 'Too Low'
                    },
                    {
                        value: 3,
                        text: 'Variation High'
                    },
                    {
                        value: 4,
                        text: 'Too Early'
                    },
                    {
                        value: 5,
                        text: 'Too Late'
                    }
                ]
            }
        }
    }

}
</script>

<style>

</style>