<!-- eslint-disable -->
<template>
	<HomeNav />
	<h3>Please Enter Your Login Information</h3>
	<section id="login">
		<form id="loginForm">
			<input style="display: block;" type="text" name="email" placeholder="Email" v-model="email">
			<input style="display: block;" type="password" name="password" placeholder="Password" v-model="password">
		</form>
		<p id="errorMessage" style="color: red;" v-if="errorMessage">{{ errorMessage }}</p>
		<AuthBtn @click="loginSubmit" btnText="Log in" />
		<router-link to="/signup"><AuthBtn btnText="Go to Signup" /></router-link>
	</section>
</template>

<script>
/* eslint-disable */
import AuthBtn from '../components/AuthBtn.vue'
import HomeNav from '../components/HomeNav.vue'

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
	name: "Login",
	components: {
		AuthBtn,
		HomeNav
	},
	setup() {
		const email = ref('')
		const password = ref('')
		const errorMessage = ref(null)

		const store = useStore()
		const router = useRouter()

		const loginSubmit = async () => {
			try {
				await store.dispatch('login', {
					email: email.value,
					password: password.value
				});
				router.push('/dashboard')
			} catch (error) {
				const errorCode = error.code;
				let errorString;
				switch (errorCode) {
					case "auth/invalid-email":
						errorString = "Invalid email";
						break;
					case "auth/user-disabled":
						errorString = "User disabled";
						break;
					case "auth/user-not-found":
						errorString = "No user found with that email, did you mean to sign up?";
						break;
					case "auth/wrong-password":
						errorString = "Wrong password";
						break;
				}
				errorMessage.value = errorString;
			}
		}
		
		return { email, password, loginSubmit, errorMessage }
	}
}
</script>

<style scoped>
h3 {
	text-align: center;
	margin-top: 0;
	margin-bottom: 1rem;
}

#login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	width: 36rem;
	margin: auto;
}

#loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--secondaryBg);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#loginForm input, #license {
    width: 32rem;
    height: 40px;
    border: none;
    border-bottom: 1px solid var(--border);
    background-color: var(--tertiaryBg);
    color: white;
    font-size: 1.2em;
    padding: 0 10px;
    margin-bottom: 10px;
}

#loginForm input:focus {
    outline: none;
}

a {
    text-decoration: none;
    color: var(--title);
	margin: 0px;
}

button {
	margin-top: 0.25em;
}
</style>