/* eslint-disable */
const defaultOptions = {
    chart: {
        id: 'vuechart-example',
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        }
    },
    xaxis: {
        type: 'numeric',
        labels: {
            show: false
        },
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
            show: true
        },
        tooltip: {
            enabled: false
        },
    },
    yaxis: {
        show: true,
        type: 'numeric',
        tickAmount: 4,
    },
    title: {
        align: 'center',
        margin: 5,
        floating: true
    },
    stroke: {
        show: true,
        lineCap: 'butt',
    },
    grid: {
        show: false
    },
    noData: {
        text: 'Awaiting Data...',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
        }
    },
    legend: {
        show: false
    },
    tooltip: {
        enabled: true,
        x: {
            show: false,
        },
        theme: 'dark',
        marker: {
            show: false
        },
    },
};

export default { defaultOptions };