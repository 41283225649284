<!-- eslint-disable -->
<template>
    <section>
        <h3>Summary</h3>
        <ul>
            <li v-for="flightPoint in flightTimeline" :key="flightPoint.index" @click="$emit('pointClicked', flightPoint.index)"><span>{{ flightPoint.name }}</span><span>Rating: <span class="rating" :class="flightPoint.rating > 6 ? 'green' : 'red'">{{ flightPoint.rating }}</span></span></li>
        </ul>
    </section>
</template>

<script>
/* eslint-disable */
export default {
    name: "FlightSummary",
    props: {
        flightTimeline: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style scoped>
section {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 8fr;
}

h3 {
    font-size: 2.5em;
    margin: 0.5em 0;
    grid-row: 1 / span 1;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-row: 2 / span 1;
    height: 100%;
    overflow-y: scroll;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
ul::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
ul {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5em;
    margin: 0.25em;
    width: 90%;
    text-align: center;
    background: var(--tertiaryBg);
    border: 2px solid var(--tertiaryBg);
    border-radius: 1em;
    padding: 5px;
    transition: .5s ease-in-out;
}

li:hover {
    border: 2px solid var(--btnSolidHover);
    cursor: pointer;
    transition: .25s ease-in-out;
}

li span {
    margin: 0.25em;
    
}

.rating {
    font-size: 1.25em;
    font-weight: 800;
}

.green {
    color: rgb(98, 255, 98);
}

.red {
    color: rgb(255, 0, 0);
}
</style>