<!-- eslint-disable -->
<template>
    <section>
        <h2>Patterns</h2>
        <Overview :overviewData="this.overviewData" v-if="showOverview" />
        <DataView :data="this.componentData" :analysis="this.analysis" v-if="this.showComponent" id="dataView" />
        <DataFeedback :rating="componentRating" :feedback="this.componentFeedback" id="feedback" v-if="this.showComponent" />
        <!-- <RatingView :overallRating="overallRating" :patternRating="patternRating" v-if="showPatternView" id="ratingView" /> -->
    </section>
</template>

<script>
/* eslint-disable */

import Overview from './Overview.vue';
import DataView from './Component/DataView.vue'
import DataFeedback from './Component/DataFeedback.vue'

export default {
    name: 'PatternView',
    components: {
        Overview,
        DataView,
        DataFeedback
    },
    props: {
        overviewData: {
            type: Object,
            required: true,
        },
        componentData: {
            type: Object,
            required: true
        },
        analysis: {
            type: Object,
            required: true
        },
        componentRating: {
            type: Number,
            required: true
        },
        componentFeedback: {
            type: Array,
            required: true
        },
        showOverview: {
            type: Boolean,
            required: true,
            default: false
        },
        showComponent: {
            type: Boolean,
            required: true
        },
    },
};
</script>

<style scoped>
#dataView {
    height: 75%;
}

/* #feedback {
    height: 20%;
} */

/* #ratingView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */
</style>