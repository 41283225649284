<!-- eslint-disable prettier/prettier !-->
<template>
    <Navbar headingTxt="" />
    <ul>
        <ProfileInfo title="Name" :userData="user.displayName" :allowEdit="true" @edited="updateName" />
        <ProfileInfo title="Email" :userData="user.email" :allowEdit="false" />
        <ProfileInfo title="License" :userData="user.license" :allowEdit="true" @edited="updateLicense" />
        <ProfileInfo title="Join Date" :userData="user.joined" :allowEdit="false" />
        <ProfileInfo title="Hours" :userData="user.hours" :allowEdit="true" @edited="updateHours" />
    </ul>
</template>

<script>
/* eslint-disable */
import Navbar from '../components/Navbar.vue'
import ProfileInfo from '../components/Profile/ProfileInfo.vue'

import { db } from '../firebase/config.js'
import { getDoc, doc, updateDoc } from 'firebase/firestore'

export default {
    name: "Profile",
    components: {
        Navbar,
        ProfileInfo
    },
    data() {
        return {
            user: {},
            showEdit: false
        }
    },
    created() {
        const uid = this.$store.state.user.uid
        // const airportsRef = collection(db, 'airports')
        const querySnapshot = getDoc(doc(db, `users/${uid}`));
        querySnapshot.then(doc => {
            const data = doc.data()

            const date = new Date(data.dateCreated.seconds*1000);

            this.user = {
                email: data.email,
                displayName: data.name,
                joined: `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`,
                license: data.licenseType,
                hours: data.hours
            }
        });
    },
    methods: {
        async updateName(newName) {
            const uid = this.$store.state.user.uid;
            this.user.displayName = newName;
            await updateDoc(doc(db, `users`, uid), {
                name: newName,
            });
        },
        async updateLicense(newLicense) {
            const uid = this.$store.state.user.uid;
            this.user.license = newLicense;
            await updateDoc(doc(db, `users`, uid), {
                licenseType: newLicense,
            });
        },
        async updateHours(newHours) {
            const uid = this.$store.state.user.uid;
            this.user.hours = newHours;
            await updateDoc(doc(db, `users`, uid), {
                hours: newHours,
            });
        }
    }
};
</script>

<style scoped>

ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>