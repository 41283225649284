<!-- eslint-disable prettier/prettier !-->
<template>
    <div>
        <ul id="labels">
            <li v-for="dataPoint in data" :key="dataPoint.name">{{ dataPoint.name }}:</li>
        </ul>
        <ul id="data">
            <li v-for="dataPoint in data" :key="dataPoint.name">{{ dataPoint.data }}{{ dataPoint.unit }}</li>
        </ul>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "DataInfo",
    props: {
        data: {
            type: Array,
            required: true
        },
    },
}
</script>

<style scoped>
ul {
    list-style: none;
    padding: 0;
}
#labels {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    margin-right: 5px;
}
#data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
li {
    font-size: 1.2rem;
}
div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>