<!-- eslint-disable prettier/prettier !-->
<template>
    <section id="import">
        <label for="upload-csv" class="custom-file-upload"><i class="fas fa-upload"></i> Upload CSV</label>
        <input @change="upload" type="file" id="upload-csv" accept=".csv">
    </section>
</template>

<script>
/* eslint-disable */

export default {
    name: "UploadBtn",
    methods: {
        upload(e) {
            this.$emit('selectFile', e.target.files[0])
        }
    }
}
</script>

<style scoped>
/* eslint-disable */

input {
    display: none;
}

.custom-file-upload {
    border: 1px solid var(--btnColor);
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    transition: ease-out 0.3s;
}

.custom-file-upload:hover {
    background-color: var(--btnHover);
}
</style>