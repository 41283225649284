/* eslint-disable prettier/prettier */
<!-- eslint-disable -->
<template>
	<router-view />
	<!-- <Footer /> -->
</template>

<script>
/* eslint-disable */
import Footer from './components/Footer.vue';

export default {
	name: 'App',
	components: {
		Footer
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Ubuntu:wght@300&display=swap');

:root {
    --primary-blue: #26a9e0;
    --primary-green: #37b34a;
    --primary-navy: #102f51;
    /* Dark Theme Colors */
    --primary: #041116;
    --secondary: #06171f;
    /* --secondary: #08222d; */ /* original */
    --tertiary: #08232e;
    /* --tertiary: #0b3343; */ /* original */
    --quaternary: #0f445a;

    --btnLight: #a8ddf3;
    --btnLightHover: #51bae6;

    --btnSolid: #264CE0;
    --btnSolidHover: #4161DF;

    --hover: #d7f0db;
    /* ------------------ */
    --background-color: var(--primary);
    --title: white;
    --border: var(--primary);
    --secondaryBg: var(--secondary);
    --tertiaryBg: var(--tertiary);
    --tertiaryHover: rgb(65, 61, 61);
    --secondaryText: rgba(160, 160, 160, 1);
    --btnColor: var(--btnSolid);
    --btnHover: var(--btnSolidHover);
    --borderColor: 1px solid var(--border);
    --boxShadow: 0px 0px 23px 4px rgba(0,0,0,0.32);
    --btnCurrent: var(--primary-blue); /* use if want to change current highlighted page color */
    --fontHeader: 'Bebas Neue', cursive;
    --fontBody: 'Ubuntu', sans-serif;
    background-color: var(--background-color);
    font-family: 'Ubuntu', sans-serif;
    color: white;
}

body {
    margin: 0px;
    height: 100vh;
}

#app {
    height: 100vh;
}

header h1 {
    font-family: var(--fontHeader);
    text-align: center;
    margin: auto;
    color: var(--title);
}

.center {
    display: flex;
    justify-content: center;
}

.chartParent {
    width: 100%;
    height: 100%;
}

.allowEvent {
    pointer-events: all;
}

@media (prefers-color-scheme: light) {
    /*! Light Mode Plebs !*/
    :root {
        /* Light Theme Colors */
        --primary: #e9f6fc;
        --secondary: #d4eef9;
        --tertiary: #bee5f6;
        --quaternary: #a8ddf3;
    }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    :root {
        font-size: 12px;
    }
}
</style>