<!-- eslint-disable prettier/prettier !-->
<template>
    <Navbar headingTxt="" />
    <main>
        <table id="flightsList">
            <tr>
                <th>Flight Date</th>
                <th>Number of Landings</th>
                <th>PeakFlights Rating</th>
                <th>Download csv</th>
                <th>View Analysis</th>
                <!-- <th>Delete Flight</th> -->
            </tr>
            <tr v-for="flight in flights" :key="flight.id">
                <td><h3>{{ flight.date }}</h3></td>
                <td><h3>{{ flight.landings }}</h3></td>
                <td><h3><span :style="{color: flight.ratingColor}">{{ flight.rating }}</span></h3></td>
                <td><div class="hoverPoint" @click.prevent="downloadCSV({url: flight.downloadURL})">
                <i class="far fa-arrow-alt-circle-down"></i>
                </div></td>
                <td><div class="hoverPoint"><i class="far fa-chart-bar"></i></div></td>
                <!-- <td><i class="far fa-trash-alt" :data="flight.id" @click="deleteFlight"></i></td> -->
            </tr>
        </table>
    </main>
</template>

<script>
/* eslint-disable */
import Navbar from '../components/Navbar.vue'

import { db } from '../firebase/config.js'
import { doc, deleteDoc, getDocs, collection, orderBy, limit, query } from 'firebase/firestore'

export default {
    name: "Flights",
    components: {
        Navbar
    },
    data() {
        return {
            flights: []
        }
    },
    created() {
        const uid = this.$store.state.user.uid
        const q = query(collection(db, `users/${uid}/flights`), orderBy("timestamp", "desc"), limit(10)); //* Get last 10 flights for user
        const querySnapshot = getDocs(q);
        querySnapshot.then(docs => {
            docs.forEach((doc) => {
                const data = doc.data();

                let date = data.timestamp.toDate();
                date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

                let rating = data.rating;
                let resultEval = null;

                if (rating > 9) {
                    resultEval = `rgb(0,255,0)`
                } else if (rating > 8) {
                    resultEval = `rgb(255,255,0)`
                } else {
                    resultEval = `rgb(255,0,0)`
                }

                let landings = data.landings;

                this.flights.push({
                    date: date,
                    landings: landings,
                    rating: rating,
                    ratingColor: resultEval,
                    downloadURL: data.downloadURL,
                    id: doc.id
                });
            })
        });
    },
    methods: {
        async deleteFlight(event) {
            console.log(event.target.getAttribute('data'));
            const id = event.target.getAttribute('data');
            const uid = this.$store.state.user.uid;
            const q = doc(collection(db, `users/${uid}/flights`), id);
            await deleteDoc(q);
            this.flights = this.flights.filter(flight => flight.id !== id);
        },
        async downloadCSV({url}) {
            window.open(url);
        }
    },
};
</script>

<style scoped>

h3 {
    margin: 10px;
}

#flightsList {
    width: 100%;
    border-collapse: collapse;
}

th {
    font-size: 1.25em;
}

main tr {
    padding: 0px;
}

main tr td {
    border-bottom: var(--tertiaryBg);
    border-bottom-width: 0.25em;
    border-bottom-style: solid;
    text-align: center;
}

main tr td div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: min-content;
    margin: auto;
}

main i:hover {
    cursor: pointer;
}

main i:hover + p {
    visibility: visible;
    opacity: 1;
    color: #fff;
}

main p {
    visibility: hidden;
    transition: 0.2s;
    margin-left: 0.25em;
    opacity: 0;
}

main p:hover {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

.hoverPoint:hover {
    cursor: pointer;
}

button {
    background-color: var(--tertiaryBg);
    border: none;
    border-radius: 0.25em;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    padding: 0.5em;
    margin: 0.5em;
    cursor: pointer;
    display: none;
}

i {
    margin-left: 0.5em;
}

.fa-trash-alt:hover {
    color: rgb(255, 68, 68);
}
</style>