<!-- eslint-disable -->
<template>
    <section>
        <h3>Flight Timeline</h3>
        <ul>
            <li v-for="flightPoint in flightTimeline" :key="flightPoint.index" @click="$emit('pointClicked', flightPoint.index)">{{ flightPoint.name }}</li>
        </ul>
    </section>
</template>

<script>
/* eslint-disable */
export default {
    name: "FlightTimeline",
    props: {
        flightTimeline: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style scoped>
section {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 8fr;
}

h3 {
    font-size: 1.5em;
    margin: 0.5em 0;
    grid-row: 1 / span 1;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-row: 2 / span 1;
    height: 100%;
    overflow-y: scroll;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
ul::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
ul {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

li {
    font-size: large;
    margin: 0.25em;
    width: 80%;
    text-align: center;
    background: var(--tertiaryBg);
    border: 2px solid var(--tertiaryBg);
    border-radius: 1em;
    padding: 5px;
    transition: .5s ease-in-out;
}

li:hover {
    border: 2px solid var(--btnSolidHover);
    cursor: pointer;
    transition: .25s ease-in-out;
}
</style>