<!-- eslint-disable prettier/prettier !-->
<template>
    <section class="viewContainer">
        <section id="main">
            <section id="notes" v-if="feedback">
                <h1>Feedback</h1>
                <ul>
                    <li v-for="feedbackPoint in feedback" :key="feedbackPoint.code">
                        <i v-if="feedbackPoint.nature == 'good'" class="fa-solid fa-square-check fa-2xl" style="color: #2bff3d;"></i>
                        <i v-if="feedbackPoint.nature == 'warning'" class="fa-solid fa-triangle-exclamation fa-2xl" style="color: yellow;"></i>
                        <i v-if="feedbackPoint.nature == 'urgent'" class="fa-solid fa-circle-exclamation fa-2xl" style="color: red;"></i>
                        {{ feedbackPoint.text }}
                    </li>
                </ul>
            </section>
            <section id="rating">
                <h2>Rating</h2>
                <section id="ratingData">
                    <h1>{{ rating.toString() }}</h1>
                    <div v-if="good">
                        <i class="fa-solid fa-check fa-2xl" style="color: #2bff3d;"></i>
                    </div>
                    <div v-if="!good">
                        <i class="fa-solid fa-xmark fa-2xl" style="color: red;"></i>
                    </div>
                </section>
            </section>
        </section>
    </section>
</template>

<script>
/* eslint-disable */
export default {
    name: "DataFeedback",
    props: {
        rating: {
            type: Number,
            default: 0
        },
        feedback: {
            type: Array,
            required: true
        }
    },
    computed: {
        good() {
            return this.rating >= 8;
        },
    }
}
</script>

<style scoped>
/* eslint-disable */
h1 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--primaryColor);
    text-align: center;
    margin: 0.25em;
}
h2 {
    margin: 0;
    font-size: 2rem;
}

#main {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

#rating {
    height: 90%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#rating h1 {
    font-size: 4rem;
    font-weight: bold;
    color: var(--primaryColor);
    text-align: center;
}

#ratingData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#notes ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
}

#notes ul li {
    text-align: center;
}
</style>