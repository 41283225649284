<!-- eslint-disable -->
<template>
    <ul>
        <li>
            <h2>Most Improved</h2>
            <summary class="blue">{{ `${mostImproved.name}` }} (<span class="green">{{ `${mostImproved.direction}${mostImproved.change}` }}</span>)</summary>
        </li>
        <li>
            <h2>Overall Best</h2>
            <summary class="blue">{{ `${overallBest.name}` }} (<span class="green">{{ `${overallBest.value}` }}</span>)</summary>
        </li>
        <li>
            <h2>Most Consistent</h2>
            <summary class="blue">{{ `${mostConsistent.name}` }} (<span class="white">{{ `${mostConsistent.direction}${mostConsistent.change}` }}</span>)</summary>
        </li>
        <li>
            <h2>Overall Weakest</h2>
            <summary class="blue">{{ `${overallWeakest.name}` }} (<span class="red"> {{ `${overallWeakest.value}` }} </span>)</summary>
        </li>
    </ul>

</template>

<script>
/* eslint-disable */
export default {
    name: "ComponentFeedback",
    props: {
        mostImproved: {
            type: Object,
            required: true
        },
        overallBest: {
            type: Object,
            required: true
        },
        mostConsistent: {
            type: Object,
            required: true
        },
        overallWeakest: {
            type: Object,
            required: true
        }
    },
    // data() {
    //     return {
    //         mostImproved: {
    //             name: 'Final',
    //             direction: '+',
    //             change: 1.2
    //         },
    //         overallBest: {
    //             name: 'Crosswind',
    //             value: 9.8
    //         },
    //         mostConsistent: {
    //             name: 'Upwind',
    //             direction: '-',
    //             change: '0.2'
    //         },
    //         overallWeakest: {
    //             name: 'Base',
    //             value: 8.1
    //         }
    //     }
    // },
}
</script>

<style scoped>
/* eslint-disable */
ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

h2 {
    font-size: 2rem;
}

summary {
    font-size: 1.5rem;
    text-align: center;
}

span {
    font-weight: bold;
}

.red {
    color: red;
}

.green {
    color: rgb(78, 255, 78);
}

.blue {
    color: rgb(75, 198, 255);
}

.white {
    color: white;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
    h2 {
        font-size: 1.5rem;
    }
    summary {
        font-size: 1.5rem;
    }
}
</style>