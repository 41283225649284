<!-- eslint-disable -->
<template>
</template>

<script>
/* eslint-disable */

export default {
    name: 'TakeoffView',
};
</script>